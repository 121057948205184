
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import common from '@/config/common.json'
import { PageContent } from '@bd/components'

export default defineComponent({
  name: 'EditProfile',
  components: {
    PageContent,
  },
  setup() {
    const { t } = useI18n()
    const { helpline } = common
    return { t, helpline }
  },
})
