<template>
  <PageContent showMobileHeader :headerLabel="t('profile.edit')">
    <div class="profile-edit">
      <div class="profile-edit-box">
        <h1 class="settings-header h-1 d-none d-md-block text-center">
          <p class="h-1">{{ t('profile.edit') }}</p>
        </h1>
        <div class="profile-edit-content">
          <div>
            <p class="body-bg py-4">
              {{ t('profile.description', { helpline }) }}
            </p>
          </div>
          <div>
            <img
              :src="require('@bd/assets/images/illustrations-edit-profile.svg')"
            />
          </div>
        </div>
        <a :href="`tel:${helpline}`" class="d-md-none link">
          <Button
            class="p-button-sm p-button-primary px-4 d-md-none"
            :label="t('profile.call')"
          />
        </a>
      </div>
    </div>
  </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import common from '@/config/common.json'
import { PageContent } from '@bd/components'

export default defineComponent({
  name: 'EditProfile',
  components: {
    PageContent,
  },
  setup() {
    const { t } = useI18n()
    const { helpline } = common
    return { t, helpline }
  },
})
</script>

<style lang="scss" scoped>
.profile-edit {
  @include flex(center, center);
  height: calc(100vh - #{$top-navigation-height});
  text-align: center;
  &-box {
    max-width: 300px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }
}
</style>
